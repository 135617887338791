import React from "react"
import Logo from "./logo"

const Header = ({ siteTitle })  => {
  return (
    <header className="header-section">
      <nav>
        <div className="flex justify-between items-center flex-wrap">
          <div className="px-10 py-3 w-full sm:w-1/2 md:w-1/2 lg:w-1/4">
            <a href="https://summersetgrills.com/">
              <Logo
                className="header-logo mx-auto"
                filename="summerset-logo.png"
                alt="summerset logo"
              />
            </a>
          </div>
          <nav class="flex px-10 py-3 justify-between w-full sm:1/2 lg:w-1/2">
            <a
              className="header-link"
              href="https://summersetgrills.com/collections/all"
            >
              Products
            </a>
            <a
              className="header-link"
              href="https://summersetgrills.com/blogs/recipes/"
            >
              Recipes
            </a>
            <a
              className="header-link"
              href="https://summersetgrills.com/blogs/inspiration/"
            >
              Inspiration
            </a>
            <a className="header-link" href="/">
              Help
            </a>
          </nav>
          <div className=" py-3 pr-5 flex justify-center lg:justify-end w-full lg:w-1/4">
            <a className="header-buttons p-2" href="/contact-us">
              Contact Us
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header
